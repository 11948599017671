import { EmailMySearch } from './EmailMySearch';

(function () {
    'use strict';
    wo$(function () {
        EmailMySearch.modalInit(wo$('#modal-email-my-search'));
    });

    WhistleOut.notifications.add(function (notification) {
        if (notification === 'SearchResultsRendered') {
            EmailMySearch.modalInit(wo$('#modal-email-my-search'));
        }
    });

}());
